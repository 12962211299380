const controls = document.querySelector(`.reviews__controls`)
const left = controls.querySelector(`.reviews__control-left`)
const right = controls.querySelector(`.reviews__control-right`)
const reviews = document.querySelector(`.reviews`)

let isMousePressed = false
let startX
let scrollLeft

const leftOnClick = () => {
  reviews.scrollLeft -= 300
}

const rightOnClick = () => {
  reviews.scrollLeft += 300
}

left.addEventListener(`click`, leftOnClick)
right.addEventListener(`click`, rightOnClick)

reviews.addEventListener('mousedown', (e) => {
  isMousePressed = true
  e.preventDefault()
  reviews.style.cursor = 'grabbing'
  startX = e.pageX - reviews.offsetLeft
  scrollLeft = reviews.scrollLeft
});
reviews.addEventListener('mouseleave', () => {
  isMousePressed = false
  reviews.style.cursor = 'default'
});
reviews.addEventListener('mouseup', () => {
  isMousePressed = false
  reviews.style.cursor = 'default'
});
reviews.addEventListener('mousemove', (e) => {
  if(!isMousePressed) return
  e.preventDefault()
  const x = e.pageX - reviews.offsetLeft
  const walk = (x - startX)
  reviews.scrollLeft = scrollLeft - walk
});